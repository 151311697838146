import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import { hydrate, render } from "react-dom";

import Router from "./router";
import i18n from "./translation";
import TagManager from "react-gtm-module";

// set up google tag managger
const TagManagerArgs = {
  gtmId: "GTM-P3P22N7"
}

TagManager.initialize(TagManagerArgs)
TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    path: "/home/",
  }
})

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);
 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}