import { createGlobalStyle } from 'styled-components';

const Styles = createGlobalStyle`

    body,
    html,
    a {
        font-family: 'Poppins', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #000000;
        overflow-x: hidden;
    }

    a:hover {
        color: rgb(30,30,30);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3.0625rem;
      
        @media only screen and (max-width: 414px) {
          font-size: 1.625rem;
          line-height: 2rem;
        }
    }

    p {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-size: 16px;
        line-height: 1.4rem;
    }

    h1 {
        font-family: 'Poppins', sans-serif;
        color: #EBEBEB;
        font-weight: 700;
        font-size: 3.450rem;
        line-height: 4rem;

        @media only scren and (max-width: 786ox) {
            font-size: 3rem;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 1.725rem;
          line-height: 2.7rem;
        }
    }

    h2 {
        font-family: 'Poppins', sans-serif;
        color: #EBEBEB;
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 2.0625rem;
      
        @media only screen and (max-width: 414px) {
          font-size: 1.1rem;
        }
    }

    h3 {
        -webkit-text-fill-color: #00FF33;
        -webkit-text-stroke: 1px #00ff33;
        font-size: 7.5rem;
    }

    h4 {
        font-family: 'Poppins', sans-serif;
        color: #EBEBEB;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3.0625rem;
      
        @media only screen and (max-width: 414px) {
          font-size: 1.625rem;
          line-height: 2rem;
        }
    }
    
    h5 {
        font-family: 'Poppins', sans-serif;
        color: #000000;
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.0625rem;
      
        @media only screen and (max-width: 414px) {
          font-size: 1.4rem;
          font-weight: 500;
        }
    }
    

    a {
        text-decoration: none;
        outline: none;
        color: #00ff33;

        :hover {
            color: #00ff33;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .report-block-image svg {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);

    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        text-align: left;
        padding-top: 2.5rem;
        padding-right: 2rem;
        background-color: #000000;
        border-left: 2px solid #00FF33;

    }

    .anticon,
    .ant-notification-notice-icon-success {
        color: #00ff33;
    }

    .collapse-css-transition {
        transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
      }
`;

export default Styles;
