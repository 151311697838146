import { lazy, Fragment } from "react";
import { Row, Col } from "antd";
import i18n from "i18next";
import { withTranslation } from "react-i18next";

import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));

const Footer = ({ t }) => {
  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const SocialLink = ({ href, src }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <Fragment>
        <S.Footer>
          <S.Container>
            <Row type="flex" justify="space-between">
              <Col lg={10} md={10} sm={12} xs={24}>
                <S.Language>{t("Contact")}</S.Language>
                <S.Large Link to={{pathname: "https://calendly.com/cpm-/cita-manager"}} target="_blank">{t("Asesoramiento Profesional")}</S.Large>
                <a href="mailto:inversores@cpmanager.io">
                  <S.Chat>inversores@cpmanager.io</S.Chat>
                  </a>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24}>
                <S.Title>{t("POLÍTICAS")}</S.Title>
                <S.Large left="true" to="/faqs">
                  {t("About")}
                </S.Large>
                <S.Large to="/" left="true">
                  {t("Terminos y Condiciones")}
                </S.Large>
                
              </Col>
              <Col lg={6} md={6} sm={12} xs={24}>
              <S.Select>
                  <S.Label htmlFor="select-lang">{t("Language")}</S.Label>
                  <S.LangSelect
                    onChange={handleChange}
                    value={i18n.language}
                    id="select-lang"
                  >
                    <option value="en">English</option>
                    <option value="es">Español</option>
                  </S.LangSelect>
                </S.Select>

              </Col>
            </Row>
          </S.Container>
        </S.Footer>
        <S.Extra>
          <S.Container border="true">
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ paddingTop: "3rem" }}
            >
              <S.NavLink to="/">
                <S.LogoContainer>
                  <SvgIcon
                    src="logo.svg"
                    aria-label="homepage"
                    width="101px"
                    height="64px"
                  />
                </S.LogoContainer>
              </S.NavLink>
              <S.FooterContainer>
              <SocialLink
                  href="https://www.instagram.com/crypto_professional_manager/"
                  src="instagram.svg"
                />                
              <SocialLink
                  href="https://www.facebook.com/cpmanager.io/"
                  src="facebook.svg"
                />                
                <SocialLink
                  href="https://twitter.com"
                  src="twitter.svg"
                />
                <SocialLink
                  href="https://www.linkedin.com/company/crypto-professional-manager"
                  src="linkedin.svg"
                />
              </S.FooterContainer>
            </Row>
          </S.Container>
        </S.Extra>
    </Fragment>
  );
};

export default withTranslation()(Footer);
