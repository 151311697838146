import { useState, lazy } from "react";
import { Row, Col, Drawer } from "antd";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Button = lazy(() => import("../../common/Button"));

const Header = ({ t }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {

    return (
      <>
        <NavLink 
          to="/aprender"
          >
          <S.CustomNavLinkSmall>
            <S.Span>{t("Aprender")}</S.Span>
          </S.CustomNavLinkSmall>
        </NavLink>
        <NavLink 
          to="/faqs"
          >
          <S.CustomNavLinkSmall>
            <S.Span>{t("About")}</S.Span>
          </S.CustomNavLinkSmall>
        </NavLink>
        <NavLink 
          to="/contacto"
          >
        <S.CustomNavLinkSmall
          style={{ width: "180px" }}
        >
          <S.Span>
            <Button>{t("Contacto")}</Button>
          </S.Span>
        </S.CustomNavLinkSmall>
        </NavLink>
      </>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" justify="space-between" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width={150} height={50} />
          </S.LogoContainer>
          {/* desktop view */}
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit={true}
        >
          <Drawer closable={false} visible={visible} onClick={showDrawer} onClose={onClose} >
            <Col  style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={showDrawer}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
