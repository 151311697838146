const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/faqs"],
    exact: true,
    component: "About",
  },
  {
    path: ["/", "/aprender"],
    exact: true,
    component: "Downloads",
  },
  {
    path: ["/", "/contacto"],
    exact: true,
    component: "Contact",
  },
];

export default routes;
